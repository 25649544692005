<template>
  <div class="backend-container">
    <Layout style="height: 100%">
      <div v-if="!isMobile()">
        <Sider ref="side" v-model="isCollapsed" style="height: 100%">
          <MenuContent :isCollapsed="isCollapsed"></MenuContent>
        </Sider>
      </div>
      <!-- mobile mode -->
      <div v-else>
        <Drawer
          class="mobile-drawer"
          placement="left"
          :closable="false"
          v-model="isCollapsed"
          width="200"
        >
          <MenuContent :isCollapsed="isCollapsed"></MenuContent>
          <div>tttt</div>
        </Drawer>
      </div>
      <Layout>
        <Header :style="{ padding: 0 }" class="layout-header-bar">
          <Icon
            @click.native="collapsedSider"
            :class="rotateIcon"
            :style="{ margin: '0 20px' }"
            type="md-menu"
            size="24"
          ></Icon>
          <h1 class="header-title">后台管理</h1>
          <div style="margin-left:auto;margin-right:10px;">
            <span style="margin-right:10px">{{userName}}</span>
            <Button  @click="logout">退出</Button>
          </div>
        </Header>
        <Content :style="{ margin: isMobile() ? '1px 0 0' : '6px' }">
          <router-view></router-view>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import { mixinDevice } from "@/util/mixin";
import MenuContent from "@/components/MenuContent";
import Store from '@/store';

export default {
  mixins: [mixinDevice],
  components: {
    MenuContent: MenuContent,
  },
  data() {
    return {
      isCollapsed: false,
      userName: null
    };
  },
  computed: {
    rotateIcon() {
      return ["menu-icon", this.isCollapsed ? "rotate-icon" : ""];
    },
  },
  methods: {
    collapsedSider() {
      if (this.isMobile()) {
        this.isCollapsed = !this.isCollapsed;
      } else {
        // this.$refs.side.toggleCollapse();
      }
    },
    logout(){
      Store.clearUserLogin();
      this.$router.push({name:'login'});
    }
  },
  mounted() {
    // this.$log.debug("home mounted");
    this.userName = Store.state.user.userName;
  },
  created() {},
};
</script>

<style lang="less" >
.backend-container {
  width: 100vw;
  height: 100vh;
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.header-title {
  font-size: 18px;
}

.menu-icon {
  transition: all 0.3s;
}
.rotate-icon {
  transform: rotate(-90deg);
}

.mobile-drawer {
  background-color: #515a6e;
}

.ivu-drawer-body {
  padding: 0;
  background-color: #515a6e;
}
</style>