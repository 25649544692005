<template>
  <div class="menu-content">
    <div class="sider-top">
      <a class="sider-logo">
        <img style="width: 32px; height: auto" :src="logoSrc" />
        <span style="font-size: 18px; color: #ffffff; margin-left: 12px"
          >家易量</span
        >
      </a>
    </div>
    <Menu
      :active-name="activeMenuName"
      :open-names="openNames"
      theme="dark"
      width="auto"
      @on-select="onMenuSelect"
    >
      <Submenu
        v-for="(menu, index) in menus"
        :key="index"
        :name="menu.menu"
        :class="menuitemClasses"
      >
        <template slot="title">
          <Icon type="ios-paper" />
          {{ menu.name }}
        </template>
        <MenuItem v-for="item in menu.items" :name="item.name" :key="item.name">
          <span>{{ item.meta.label }}</span>
        </MenuItem>
        <!-- <MenuItem name="active-list">
          <span>激活用户</span>
        </MenuItem> -->
      </Submenu>
    </Menu>
  </div>
</template>

<script>
import { mixinDevice } from "@/util/mixin";
import { leftMenuItems } from "@/router";
import Store from "@/store";

export default {
  name: "MenuContent",
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menus: [],
      activeMenuName: this.store.state.activeName,
    };
  },
  mixins: [mixinDevice],
  computed: {
    menuitemClasses() {
      return [
        "menu-item",
        this.isCollapsed && !this.isMobile() ? "collapsed-menu" : "",
      ];
    },
    logoSrc() {
      return this.isCollapsed && !this.isMobile()
        ? "http://jiayiliang.cn/favicon.png"
        : "http://jiayiliang.cn/favicon.png";
    },
    openNames() {
      var names = this.menus.map((item) => {
        return item.menu;
      });
      this.$log.debug("names: ", names);
      return names;
    },
  },
  methods: {
    onMenuSelect(name) {
      if (name == this.activeMenuName) return;
      this.store.setActiveName(name);
      this.activeMenuName = name;
      this.$router.push({ name: this.activeMenuName });
    },
    filterMenus() {
      //过滤menus
      const user = Store.state.user;
      if (user) {
        const userType = user.user_type;
        this.$log.debug("userType:" + userType);
        let newLeftMenuItems = JSON.parse(JSON.stringify(leftMenuItems));
        newLeftMenuItems.forEach((menu) => {
          menu.items = menu.items.filter((item) => {
            return item.meta.type.indexOf(userType) >= 0;
          });
          if (menu.items.length <= 0) {
            menu.notShow = true;
          }else {
            menu.notShow = false;
          }
        });
        this.$log.debug(newLeftMenuItems);
        this.menus = newLeftMenuItems.filter((menu) => {
          return !menu.notShow;
        });
        this.$log.debug(this.menus);
      }
      let routerPath = this.$route.path;
      if (routerPath != null) {
        this.menus.forEach((menu) => {
          menu.items.forEach((item) => {
            if (routerPath.indexOf(item.name) >= 0) {
              this.store.setActiveName(item.name);
              this.activeMenuName = item.name;
            }
          });
        });
      }
    },
  },
  mounted() {
    this.filterMenus();
  }
};
</script>

<style lang="less" >
.menu-content {
  height: 100%;
}

.sider-top {
  height: 63px;
  display: block;
  line-height: 63px;
  text-align: center;
  box-sizing: border-box;
  border-bottom: 1px solid #363e4f;
}

.sider-logo {
  cursor: pointer;
  background: transparent;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}

.sider-logo img {
  height: 90%;
  vertical-align: middle;
}
</style>